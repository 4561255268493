$github-corner-width: 5rem;

#github-corner {
  border-bottom: 0;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
  z-index: 1;
}

#github-corner svg {
  fill: var(--foreground);
  color: var(--background);
  aspect-ratio: 1;
  width: $github-corner-width;
}

#github-corner:hover .octo-arm,
#github-corner:focus .octo-arm {
  animation: octocat-wave 0.56s ease-in-out;
}

@keyframes octocat-wave {
  0%,
  to {
    transform: rotate(0);
  }

  20%,
  60% {
    transform: rotate(-25deg);
  }

  40%,
  80% {
    transform: rotate(10deg);
  }
}

#dark-mode-toggle {
  --width: calc(#{$github-corner-width} / 2);

  position: fixed;
  right: 3ch;
  text-decoration: none;
  top: calc($github-corner-width + 1.5rem);
  z-index: 1;
  aspect-ratio: 1;
  width: var(--width);
  border-radius: 50%;
  overflow: hidden;
  padding: 0.5rem;
  display: grid;
  gap: var(--width);
  border: none;
  box-shadow: 0 0 0.1rem 0.1rem var(--foreground);
}

#home-button {
  --width: calc(#{$github-corner-width} / 2);

  position: fixed;
  right: 3ch;
  text-decoration: none;
  top: calc($github-corner-width + 1.5rem + var(--width) + 1.5rem);
  z-index: 1;
  aspect-ratio: 1;
  width: var(--width);
  border-radius: 50%;
  padding: 0.5rem;
  border: none;
  box-shadow: 0 0 0.1rem 0.1rem var(--foreground);
}

#print-button {
  --width: calc(#{$github-corner-width} / 2);

  position: fixed;
  right: 3ch;
  text-decoration: none;
  top: calc($github-corner-width + 1.5rem + 2 * ( var(--width) + 1.5rem));
  z-index: 1;
  aspect-ratio: 1;
  width: var(--width);
  border-radius: 50%;
  padding: 0.5rem;
  border: none;
  box-shadow: 0 0 0.1rem 0.1rem var(--foreground);
}
