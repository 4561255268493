footer p {
  text-align: center;
}

#introduction > h1 {
  text-align: center;
}

#introduction > h3 {
  text-align: center;
}

#call-to-action > h3 {
  text-align: center;
}

header > h2 {
  text-align: center;
}

#cv-header {
  text-align: center;
}
