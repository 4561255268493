body {
  background-color: var(--background, --default-background);
  color: var(--foreground, --default-foreground);
}

footer {
  background-color: var(--non-specific-background, --default-background);
  color: white;
}

#skip-to-content {
  background-color: var(--background, --default-background);
  color: var(--accent-color, --default-accent-color);
}

a {
  color: var(--foreground, --default-foreground);
}

a:hover,
a:focus-visible,
a:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--background), 0 0 0 4px var(--accent-color, --default-accent-color);
  color: var(--accent-color, --default-accent-color);
}

::selection {
  background-color: var(--accent-color, --default-accent-color);
}

.project-card {
  background-color: var(--background);
  box-shadow: 3px 3px 5px 0 rgb(0 0 0 / 67%);
}

.project-card-title {
  color: white;
  background-color: rgb(0 0 0 / 30%);
}

.project-card-title::after {
  background-color: white;
}

.project-card-description {
  background-color: white;
  color: black;
}

#call-to-action > button {
  background: var(--background);
  color: var(--foreground);
}
