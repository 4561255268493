@use "sass:color";

$dark-non-black: #1f1f1f;
$bright-non-white: #f1f1f7;
$pastel-blue: #3f72f3;

.dark-theme,
body[color-theme="dark"] {
  --background: #{$dark-non-black};
  --foreground: #{$bright-non-white};
  --non-specific-background: #{color.adjust($dark-non-black, $lightness: 10%)};
  --accent-color: #{$pastel-blue};
}

.dev-theme,
body[color-theme="dev"] {
  --background: rgb(25 200 200);
  --foreground: rgb(10 10 10);
  --non-specific-background: rgb(50 50 50);
  --accent-color: rgb(200 10 9);
}

.light-theme,
body[color-theme="light"] {
  --background: #{$bright-non-white};
  --foreground: #{$dark-non-black};
  --non-specific-background: #{color.adjust($dark-non-black, $lightness: 10%)};
  --accent-color: #{$pastel-blue};
}
