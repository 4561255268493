#main-icon {
  border-radius: 100%;
  border: thick solid;
  border-color: var(--accent-color, --default-accent-color);
}

#call-to-action > button {
  border: medium solid;
  border-color: var(--foreground);
  border-radius: 0.375rem;
}
