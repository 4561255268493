*, :after, :before {
  box-sizing: border-box;
}

#main-icon {
  border: thick solid;
  border-color: var(--accent-color, --default-accent-color);
  margin-bottom: 2rem;
  margin-inline: auto;
  border-radius: 100%;
  margin-top: 2rem;
  display: block;
}

#call-to-action > button {
  background: var(--background);
  border: solid;
  border-color: var(--foreground);
  color: var(--foreground);
  cursor: pointer;
  letter-spacing: -.01em;
  border-radius: .375rem;
  margin-inline: auto;
  padding: .6em 1em;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.3;
  display: flex;
}

body {
  background-color: var(--background, --default-background);
  color: var(--foreground, --default-foreground);
  margin: 0;
  font-family: Merriweather, serif;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 2;
}

footer {
  background-color: var(--non-specific-background, --default-background);
  color: #fff;
  margin-top: 2em;
}

#skip-to-content {
  background-color: var(--background, --default-background);
  border: 2px solid var(--non-specific-background);
  color: var(--accent-color, --default-accent-color);
  border-radius: .1rem;
  margin-top: .5rem;
  margin-left: .5rem;
  position: absolute;
  transform: translateX(-1000%);
}

a {
  color: var(--foreground, --default-foreground);
}

a:hover, a:focus-visible, a:focus {
  box-shadow: 0 0 0 2px var(--background), 0 0 0 4px var(--accent-color, --default-accent-color);
  color: var(--accent-color, --default-accent-color);
  outline: none;
}

::selection {
  background-color: var(--accent-color, --default-accent-color);
}

.project-card {
  background-color: var(--background);
  max-height: 40ch;
  max-width: 30ch;
  min-height: 30ch;
  min-width: 25ch;
  border-radius: .5rem;
  padding-inline: 1ch;
  text-decoration: none;
  box-shadow: 3px 3px 5px #000000ab;
}

.project-card-title {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  color: #fff;
  text-transform: uppercase;
  background-color: #0000004d;
  margin-top: 40%;
  display: grid;
}

.project-card-title:after {
  content: "";
  height: .2rem;
  background-color: #fff;
  border-radius: .2em;
}

.project-card-description {
  color: #000;
  background-color: #fff;
  border-radius: .5em;
  margin-top: -.5em;
  padding: 1em;
  font-size: smaller;
}

.dark-theme, body[color-theme="dark"] {
  --background: #1f1f1f;
  --foreground: #f1f1f7;
  --non-specific-background: #393939;
  --accent-color: #3f72f3;
}

.dev-theme, body[color-theme="dev"] {
  --background: #19c8c8;
  --foreground: #0a0a0a;
  --non-specific-background: #323232;
  --accent-color: #c80a09;
}

.light-theme, body[color-theme="light"] {
  --background: #f1f1f7;
  --foreground: #1f1f1f;
  --non-specific-background: #393939;
  --accent-color: #3f72f3;
}

header {
  grid-template-rows: auto;
  grid-auto-flow: column;
  gap: 1em;
  margin-bottom: 10vh;
  padding: .5em;
  padding-inline: 2rem;
  display: none;
}

main {
  max-width: 65ch;
  margin-inline: auto;
  padding: 1em 3ch;
}

nav {
  grid-template-rows: auto;
  grid-auto-flow: column;
  justify-items: center;
  gap: .5vw;
  padding-inline: 0;
  font-family: Roboto, sans-serif;
  display: grid;
}

footer p {
  text-align: center;
  margin-block: 0;
  font-family: Roboto, sans-serif;
  font-size: .8rem;
  font-style: italic;
  font-weight: 100;
}

#introduction {
  margin-top: 3em;
}

#introduction > h3 {
  text-align: center;
  margin-top: -1em;
  margin-bottom: 1em;
  font-style: italic;
  font-weight: 100;
}

h2 {
  margin-block: 1.3em;
}

header > h2 {
  font-style: bold;
  text-align: center;
  margin-block: 0;
}

#introduction > h1 {
  text-align: center;
  font-size: 2rem;
}

#call-to-action > h3 {
  text-align: center;
  font-style: italic;
  font-weight: 100;
}

#cv-header {
  --inline-padding: 1ch;
  --inline-height: .1em;
  text-align: center;
  display: flex;
}

#project-card-container {
  justify-content: center;
  gap: .7em;
  padding: 1em;
  display: grid;
}

@media (min-width: 63ch) {
  #project-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

#card-mentor {
  background-image: url("mentor_front_page.9147b82d.png");
  background-position: 70%;
  background-size: cover;
}

#card-aoc {
  background-image: url("aoc_stars.6dff34cc.png");
  background-size: cover;
}

#card-cstars {
  background-image: url("cstars_icon_dark_bg.08401367.png");
  background-position: 99%;
  background-size: cover;
}

#card-dotfiles {
  background-image: url("dotfile_icon_dark_bg.e3d9528e.png");
  background-position: 80%;
  background-size: cover;
}

#github-corner {
  z-index: 1;
  border-bottom: 0;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

#github-corner svg {
  aspect-ratio: 1;
  color: var(--background);
  fill: var(--foreground);
  width: 5rem;
}

#github-corner:hover .octo-arm, #github-corner:focus .octo-arm {
  animation: octocat-wave .56s ease-in-out;
}

@keyframes octocat-wave {
  0%, to {
    transform: rotate(0);
  }

  20%, 60% {
    transform: rotate(-25deg);
  }

  40%, 80% {
    transform: rotate(10deg);
  }
}

#dark-mode-toggle {
  --width: calc(5rem / 2);
  aspect-ratio: 1;
  box-shadow: 0 0 .1rem .1rem var(--foreground);
  gap: var(--width);
  width: var(--width);
  z-index: 1;
  border: none;
  border-radius: 50%;
  padding: .5rem;
  text-decoration: none;
  display: grid;
  position: fixed;
  top: 6.5rem;
  right: 3ch;
  overflow: hidden;
}

#home-button {
  --width: calc(5rem / 2);
  aspect-ratio: 1;
  box-shadow: 0 0 .1rem .1rem var(--foreground);
  right: 3ch;
  top: calc(6.5rem + var(--width)  + 1.5rem);
  width: var(--width);
  z-index: 1;
  border: none;
  border-radius: 50%;
  padding: .5rem;
  text-decoration: none;
  position: fixed;
}

#print-button {
  --width: calc(5rem / 2);
  aspect-ratio: 1;
  box-shadow: 0 0 .1rem .1rem var(--foreground);
  right: 3ch;
  top: calc(6.5rem + 2 * (var(--width)  + 1.5rem) );
  width: var(--width);
  z-index: 1;
  border: none;
  border-radius: 50%;
  padding: .5rem;
  text-decoration: none;
  position: fixed;
}

@font-face {
  font-family: Merriweather;
  font-style: normal;
  font-weight: 400;
  src: url("merriweather-regular.c4878bcb.woff2") format("woff2"), url("merriweather-regular.3167fa5d.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  src: url("roboto-bold.699eb688.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  src: url("roboto-medium.0b424ae6.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  src: url("roboto-thin-italic.ce765c82.woff") format("woff");
}

h1, h2, h3 {
  font-family: Roboto, sans-serif;
  line-height: 1.2;
}

#skip-to-content:focus {
  transition: all .2s;
  transform: translateX(0);
}

html {
  scroll-behavior: smooth;
}

#call-to-action > button:hover, #call-to-action > button:focus {
  box-shadow: inset 0 0 .1rem .1rem;
}

section > h2 {
  display: flex;
}

section > h2:after {
  background-color: var(--foreground);
  content: "";
  height: .1em;
  flex: auto;
  align-self: center;
  margin-left: 1ch;
}

#cv-header:after {
  background-color: var(--foreground);
  content: "";
  height: var(--inline-height);
  margin-left: var(--inline-padding);
  flex: auto;
  align-self: center;
}

#cv-header:before {
  background-color: var(--foreground);
  content: "";
  height: var(--inline-height);
  margin-right: var(--inline-padding);
  flex: auto;
  align-self: center;
}

@media print {
  @page {
    margin: 2cm;
  }

  body {
    color: #000;
    font: 12pt / 1.3 Georgia, Times New Roman, Times, serif;
    background: #fff !important;
  }

  h1 {
    font-size: 24pt;
  }

  h2, h3, h4 {
    margin-top: 25px;
    font-size: 16pt;
  }

  #introduction > p, #github-corner, #dark-mode-toggle, #print-button, #home-button {
    display: none;
  }
}

/*# sourceMappingURL=index.e4d2bb10.css.map */
