@use "utils" as *;

header {
  @include horizontal-one-line(1em);

  display: none;
}

main {
  max-width: 65ch;
}

nav {
  @include horizontal-one-line(0.5vw, 0);

  justify-items: center;
}
