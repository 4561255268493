@media print {
  @page { margin: 2cm; }

  body {
    font: 12pt "Georgia", "Times New Roman", "Times", serif;
    line-height: 1.3;
    background: #fff !important;
    color: #000;
  }

  h1 {
    font-size: 24pt;
  }

  h2,
  h3,
  h4 {
    font-size: 16pt;
    margin-top: 25px;
  }

  #introduction > p,
  #github-corner,
  #dark-mode-toggle,
  #print-button,
  #home-button {
    display: none;
  }
}
