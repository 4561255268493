$font-base: "../fonts";
$font-base-roboto: "../fonts/roboto";

@font-face {
  font-family: "Merriweather";
  src: url("#{$font-base}/merriweather/merriweather-regular.woff2")
      format("woff2"),
    url("#{$font-base}/merriweather/merriweather-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("#{$font-base-roboto}/roboto-bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: url("#{$font-base-roboto}/roboto-medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  src: url("#{$font-base-roboto}/roboto-thin-italic.woff") format("woff");
  font-weight: thin;
  font-style: italic;
}

body {
  font-family: "Merriweather", serif;
  font-weight: normal;
  font-size: 1.1rem;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
}

nav {
  font-family: "Roboto", sans-serif;
}

footer p {
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-weight: thin;
}

#introduction > h3 {
  font-style: italic;
  font-weight: thin;
}

#call-to-action > h3 {
  font-style: italic;
  font-weight: thin;
}

#call-to-action > button {
  font-family: "Roboto", sans-serif;
}

header > h2 {
  font-style: bold;
}
