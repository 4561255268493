$max-card-width: 30ch;

.project-card {
  border-radius: 0.5rem;
  padding-inline: 1ch;
  min-width: 25ch;
  max-width: $max-card-width;
  min-height: 30ch;
  max-height: 40ch;
  text-decoration: none;
}

.project-card-title {
  margin-top: 40%;
  text-transform: uppercase;
  backdrop-filter: blur(3px);
  display: grid;
}

.project-card-title::after {
  content: "";
  height: 0.2rem;
  border-radius: 0.2em;
}

.project-card-description {
  margin-top: -0.5em;
  padding: 1em;
  border-radius: 0.5em;
  font-size: smaller;
}

#project-card-container {
  display: grid;
  gap: 0.7em;
  padding: 1em;
  justify-content: center;

  @media (min-width: #{2.1 * $max-card-width}) {
    grid-template-columns: repeat(2, 1fr);
  }
}

#card-mentor {
  background-image: url("../images/mentor_front_page.png");
  background-size: cover;
  background-position: 70%;
}

#card-aoc {
  background-image: url("../images/aoc_stars.png");
  background-size: cover;
}

#card-cstars {
  background-image: url("../images/cstars_icon_dark_bg.png");
  background-size: cover;
  background-position: 99%;
}

#card-dotfiles {
  background-image: url("../images/dotfile_icon_dark_bg.png");
  background-size: cover;
  background-position: 80%;
}
