body {
  margin: 0;
}

header {
  margin-bottom: 10vh;
  padding: 0.5em;
}

footer {
  margin-top: 2em;
}

main {
  margin-inline: auto;
  padding: 1em 3ch;
}

footer p {
  margin-block: 0;
}

#main-icon {
  display: block;
  margin-inline: auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

#introduction {
  margin-top: 3em;
}

#introduction > h3 {
  margin-top: -1em;
  margin-bottom: 1em;
}

#call-to-action > button {
  display: flex;
  margin-inline: auto;
  padding: 0.6em 1em;
}

h2 {
  margin-block: 1.3em;
}

header > h2 {
  margin-block: 0;
}

#skip-to-content {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  border: 2px solid var(--non-specific-background);
  border-radius: 0.1rem;
}
